import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

// Pages
import Header from '../../components/pages/early-access/thank-you/Header';
import Nav from '../../components/pages/index/Nav';

const IndexPage = () => (
    <Layout>
        <SEO title="ruzu" />

        <Nav />

        <div className="globalContent2">
            <main>
                <Header />
            </main>
        </div>
    </Layout>
)

export default IndexPage
